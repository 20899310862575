<p class="occupants-heading">{{{o.__('Features')}}}</p>
<ul class="features-list">
{[ if (o.passwordprotected) { ]}
<li class="feature" title="{{{ o.__('This groupchat requires a password before entry') }}}"><span class="fa fa-lock"></span>{{{ o.__('Password protected') }}}</li>
{[ } ]}
{[ if (o.unsecured) { ]}
<li class="feature" title="{{{ o.__('This groupchat does not require a password upon entry') }}}"><span class="fa fa-unlock"></span>{{{ o.__('No password') }}}</li>
{[ } ]}
{[ if (o.hidden) { ]}
<li class="feature" title="{{{ o.__('This groupchat is not publicly searchable') }}}"><span class="fa fa-eye-slash"></span>{{{ o.__('Hidden') }}}</li>
{[ } ]}
{[ if (o.public_room) { ]}
<li class="feature" title="{{{ o.__('This groupchat is publicly searchable') }}}"><span class="fa fa-eye"></span>{{{ o.__('Public') }}}</li>
{[ } ]}
{[ if (o.membersonly) { ]}
<li class="feature" title="{{{ o.__('this groupchat is restricted to members only') }}}"><span class="fa fa-address-book"></span>{{{ o.__('Members only') }}}</li>
{[ } ]}
{[ if (o.open) { ]}
<li class="feature" title="{{{ o.__('Anyone can join this groupchat') }}}"><span class="fa fa-globe"></span>{{{ o.__('Open') }}}</li>
{[ } ]}
{[ if (o.persistent) { ]}
<li class="feature" title="{{{ o.__('This groupchat persists even if it\'s unoccupied') }}}"><span class="fa fa-save"></span>{{{ o.__('Persistent') }}}</li>
{[ } ]}
{[ if (o.temporary) { ]}
<li class="feature" title="{{{ o.__('This groupchat will disappear once the last person leaves') }}}"><span class="fa fa-snowflake"></span>{{{ o.__('Temporary') }}}</li>
{[ } ]}
{[ if (o.nonanonymous) { ]}
<li class="feature" title="{{{ o.__('All other groupchat participants can see your XMPP username') }}}"><span class="fa fa-id-card"></span>{{{ o.__('Not anonymous') }}}</li>
{[ } ]}
{[ if (o.semianonymous) { ]}
<li class="feature" title="{{{ o.__('Only moderators can see your XMPP username') }}}"><span class="fa fa-user-secret"></span>{{{ o.__('Semi-anonymous') }}}</li>
{[ } ]}
{[ if (o.moderated) { ]}
<li class="feature" title="{{{ o.__('Participants entering this groupchat need to request permission to write') }}}"><span class="fa fa-gavel"></span>{{{ o.__('Moderated') }}}</li>
{[ } ]}
{[ if (o.unmoderated) { ]}
<li class="feature" title="{{{ o.__('Participants entering this groupchat can write right away') }}}"><span class="fa fa-info-circle"></span>{{{ o.__('Not moderated') }}}</li>
{[ } ]}
{[ if (o.mam_enabled) { ]}
<li class="feature" title="{{{ o.__('Messages are archived on the server') }}}"><span class="fa fa-database"></span>{{{ o.__('Message archiving') }}}</li>
{[ } ]}
</ul>
